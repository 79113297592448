.we-do-page {
  width: 100vw;
  height: auto;
}

.page-tracker {
  position: fixed;
  left: 0px;
  top: 3rem;
  height: calc(100vh - 80px);
  width: 225px;
  text-align: left;
  background-color: #f0f0f0;
  background-image: url("../images/grey.png");
  padding-top: 100px;
  box-shadow:  -8px 0 10px -6px rgba(0,0,0,0.4);
}

.we-do-body {
  width: calc(100vw - 225px);
  height: 100vh;
  margin-left: 225px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url("../images/grey.png");
  color: #5c5c5c;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-self: center;
  text-align: center;
}

.header h2 {
  margin-bottom: 30px;
}

.header p {
  /* margin-top: 0; */
  margin: 0 7rem;
  line-height: 25px;
}

.inner-page-tracker {
  margin-right: 20px;
  text-align: right;
}

.inner-page-tracker button {
  margin: 10px 0;
}

.logo {
  height: 200px;
  width: auto;
  align-self: center;
  margin-top: 4rem;
}

.inner-page-tracker button span {
  color: #5c5c5c;
  font-family: 'Padaloma';
  font-size: 12px;
  /* letter-spacing: 2px; */
  text-align: end;
}