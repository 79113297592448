.photos {
  width: 90%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.photos img {
  height: 25vh;
  width: auto;
  /* background-position: center; */
  /* background-size: cover; */
  transition: all 1s;
}

.photos img:hover {
  height:28vh;
  width: auto;
  transition: all 1s;
}