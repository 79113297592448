.charts {
  width: 95%;
  max-width: calc(100vw);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center !important;
  margin-top: 100px;
}

.line-chart {
  margin-left: 100px;
  margin-right: 150px;
}

.pie-chart {
  width: 400 !important;
}

.pie-chart svg {
  width: 400 !important;
}

.data-top {
  display: flex;
  flex-direction: row;
}