.app {
  width: auto;
  height: 60vh;
  display: flex;
  flex-wrap: wrap;
}

.app img {
  height: 100%;
  width: auto;
  /* background-position: center; */
  /* background-size: cover; */
  transition: all 1s;
}