nav {
  height: 80px;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  color: #5c5c5c;
  z-index: 1000;
  box-shadow: 0 4px 4px -3px rgba(0,0,0,.4);
}

nav a {
  text-decoration: none;
  color: #5c5c5c;
}

nav .Logo {
  margin-left: 50px;
  font-family: 'Padaloma';
  font-weight: 300;
  letter-spacing: 4px;
}

.Logo a img {
  height: 80px;
  width: auto;
  margin-bottom: -15px;
}

nav ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin-right: 50px;
}

nav ul li a {
  margin: 0 10px;
  font-size: 16px;
  font-family: 'Padaloma';
  font-weight: 300;
}